export const translations = {
  fr: {
    title: "Mes publications",
    types: "Types",
    trier: "Ranger par",
    motcles: "Mots-clés",
  },
  en: {
    title: "My papers",
    types: "Types",
    trier: "Sort by",
    motcles: "Keywords",
  },
};

export const tags = [
  { id: 1, fr: "Groupes $p$-adiques", en: "$p$-adic groups" },
  { id: 2, fr: "Langlands", en: "Langlands" },
  { id: 3, fr: "Immeuble de Bruhat-Tits", en: "Bruhat-Tits building" },
  { id: 4, fr: "Deligne-Lusztig", en: "Deligne-Lusztig" },
  { id: 5, fr: "$\\ell$-blocs", en: "$\\ell$-blocks" },
  { id: 6, fr: "Blocs stables", en: "Stable blocks" },
  { id: 7, fr: "Niveau 0", en: "Depth 0" },
  { id: 8, fr: "Représentations modulaires", en: "Modular representations" },
  { id: 9, fr: "Système d'idempotents", en: "Systems of idempotents" },
  { id: 10, fr: "Système de coefficients", en: "Coefficient systems" },
  { id: 11, fr: "Équivalence de catégories", en: "Equivalence of categories" },
  { id: 12, fr: "Représentations unipotentes", en: "Unipotent representations" },
  { id: 13, fr: "$(d,1)$-séries", en: "$(d,1)$-series" },
  { id: 14, fr: "Coefficients dans $\\overline{\\mathbb{Z}}[\\frac{1}{p}]$", en: "$\\overline{\\mathbb{Z}}[\\frac{1}{p}]$ coefficients" },
  { id: 15, fr: "Groupes réductifs finis", en: "Finite reductive groups" },
  {id: 16, fr: "$\\mathrm{GL}_n$", en: "$\\mathrm{GL}_n$" },
  {id: 17, fr: "Problèmes de distinction", en: "Distinction problems" },
  {id: 18, fr: "$\\mathrm{SL}_2$", en: "$\\mathrm{SL}_2$" },
  {id: 19, fr: "Conjecture de Prasad", en: "Prasad conjecture" },
  {id: 20, fr: "Conjecture de Jacquet", en: "Jacquet conjecture" }  
]

export const publiTypes = [
  { id: 1, fr: "Publication", en: "Publication" },
  { id: 2, fr: "Pré-publication", en: "Preprint" },
  { id: 3, fr: "Thèse", en: "Thesis" }
]

export const publications = [
  {
    id: 7,
    tags: [12,16,17,18,19,20],
    publiType: 1,
    date: 2024,
    file: "distinction.pdf",
    coAuthors: ["Peiyi Cui","Hengfei Lu"],
    fr: {
      "title": "Modulo $\\ell$ distinction problems",
      "journal": "Compositio Mathematica, 160(10), pp. 2285–2321",
      "abstract": "This paper concerns the $\\ell$-modular representations of a connected reductive group $G$ distinguished by a Galois involution, with $\\ell$ an odd prime different from $p$. We start by proving a general theorem allowing to lift supercuspidal $\\overline{\\mathbf{F}}_{\\ell}$-representations of $\\mathrm{GL}_n(F)$ distinguished by an arbitrary closed subgroup $H$ to a distinguished supercuspidal $\\overline{\\mathbf{Q}}_{\\ell}$-representation. Given a quadratic field extension $E/F$ and an irreducible $\\overline{\\mathbf{F}}_{\\ell}$-representation $\\pi$ of $\\mathrm{GL}_n(E)$, we verify the Jacquet conjecture in the modular setting that if the Langlands parameter $\\phi_\\pi$ is irreducible and conjugate-self-dual, then $\\pi$ is either $\\mathrm{GL}_n(F)$-distinguished or $(\\mathrm{GL}_n(F),\\omega_{E/F})$-distinguished, but not both, which extends one result of Sécherre to the case $p=2$. We give another application of our lifting theorem for supercuspidal representations distinguished by a unitary involution, extending one result of Zou to $p=2$. After that, we give a complete classification of the $\\mathrm{GL}_2(F)$-distinguished representations of $\\mathrm{GL}_2(E)$. Using this classification we discuss a modular version of the Prasad conjecture for $\\mathrm{PGL}_2$. We show that the \"classic\" Prasad conjecture fails in the modular setting. We propose a solution using non-nilpotent Weil-Deligne representations. Finally, we apply the restriction method of Anandavardhanan and Prasad to classify the $\\mathrm{SL}_2(F)$-distinguished modular representations of $\\mathrm{SL}_2(E)$."
    },
    en: {
      "title": "Modulo $\\ell$ distinction problems",
      "journal": "Compositio Mathematica, 160(10), pp. 2285–2321",
      "abstract": "This paper concerns the $\\ell$-modular representations of a connected reductive group $G$ distinguished by a Galois involution, with $\\ell$ an odd prime different from $p$. We start by proving a general theorem allowing to lift supercuspidal $\\overline{\\mathbf{F}}_{\\ell}$-representations of $\\mathrm{GL}_n(F)$ distinguished by an arbitrary closed subgroup $H$ to a distinguished supercuspidal $\\overline{\\mathbf{Q}}_{\\ell}$-representation. Given a quadratic field extension $E/F$ and an irreducible $\\overline{\\mathbf{F}}_{\\ell}$-representation $\\pi$ of $\\mathrm{GL}_n(E)$, we verify the Jacquet conjecture in the modular setting that if the Langlands parameter $\\phi_\\pi$ is irreducible and conjugate-self-dual, then $\\pi$ is either $\\mathrm{GL}_n(F)$-distinguished or $(\\mathrm{GL}_n(F),\\omega_{E/F})$-distinguished, but not both, which extends one result of Sécherre to the case $p=2$. We give another application of our lifting theorem for supercuspidal representations distinguished by a unitary involution, extending one result of Zou to $p=2$. After that, we give a complete classification of the $\\mathrm{GL}_2(F)$-distinguished representations of $\\mathrm{GL}_2(E)$. Using this classification we discuss a modular version of the Prasad conjecture for $\\mathrm{PGL}_2$. We show that the \"classic\" Prasad conjecture fails in the modular setting. We propose a solution using non-nilpotent Weil-Deligne representations. Finally, we apply the restriction method of Anandavardhanan and Prasad to classify the $\\mathrm{SL}_2(F)$-distinguished modular representations of $\\mathrm{SL}_2(E)$."
    }
  },
  {
    id: 6,
    tags: [1,2,3,7,14,15],
    publiType: 2,
    date: 2022,
    file: "bloczero.pdf",
    coAuthors: ["Jean-François Dat"],
    fr: {
      "title": "Depth zero representations over $\\overline{\\mathbb{Z}}[\\frac{1}{p}]$",
      "journal": "Pré-publication : arXiv:2202.03982 (2022)",
      "abstract": "We consider the category of depth $0$ representations of a $p$-adic quasi-split reductive group with coefficients in $\\overline{\\mathbb{Z}}[\\frac{1}{p}]$. We prove that the blocks of this category are in natural bijection with the connected components of the space of tamely ramified Langlands parameters for $G$ over $\\overline{\\mathbb{Z}}[\\frac{1}{p}]$. As a particular case, this depth $0$ category is thus indecomposable when the group is tamely ramified. Along the way we prove a similar result for finite reductive groups. We then outline a potential application to the Fargues-Scholze and Genestier-Lafforgue semisimple local Langlands correspondences. Namely, contingent on a certain \"independence of $\\ell$\" property,  our results imply that these correspondences take depth $0$ representations to tamely ramified parameters."
    },
    en: {
      "title": "Depth zero representations over $\\overline{\\mathbb{Z}}[\\frac{1}{p}]$",
      "journal": "Preprint: arXiv:2202.03982 (2022)",
      "abstract": "We consider the category of depth $0$ representations of a $p$-adic quasi-split reductive group with coefficients in $\\overline{\\mathbb{Z}}[\\frac{1}{p}]$. We prove that the blocks of this category are in natural bijection with the connected components of the space of tamely ramified Langlands parameters for $G$ over $\\overline{\\mathbb{Z}}[\\frac{1}{p}]$. As a particular case, this depth $0$ category is thus indecomposable when the group is tamely ramified. Along the way we prove a similar result for finite reductive groups. We then outline a potential application to the Fargues-Scholze and Genestier-Lafforgue semisimple local Langlands correspondences. Namely, contingent on a certain \"independence of $\\ell$\" property,  our results imply that these correspondences take depth $0$ representations to tamely ramified parameters."
    }
  },
  {
    id: 5,
    tags: [1,3,4,5,8,9,12,13,6,15],
    publiType: 1,
    date: 2023,
    file: "unipotentlblocs.pdf",
    coAuthors: [],
    fr: {
      "title": "Unipotent $\\ell$-blocks for simply connected $p$-adic groups",
      "journal": "Algebra Number Theory 17 (2023), no.9, 1533–1572",
      "abstract": "Let $F$ be a non-archimedean local field and $G$ the $F$-points of a connected simply-connected reductive group over $F$. In this paper, we study the unipotent $\\ell$-blocks of $G$, for $\\ell \\neq p$. To that end, we introduce the notion of $(d,1)$-series for finite reductive groups. These series form a partition of the irreducible representations and are defined using Harish-Chandra theory and $d$-Harish-Chandra theory. The $\\ell$-blocks are then constructed using these $(d,1)$-series, with $d$ the order of $q$ modulo $\\ell$, and consistent systems of idempotents on the Bruhat-Tits building of $G$. We also describe the stable $\\ell$-block decomposition of the depth zero category of an unramified classical group."
    },
    en: {
      "title": "Unipotent $\\ell$-blocks for simply connected $p$-adic groups",
      "journal": "Algebra Number Theory 17 (2023), no.9, 1533–1572",
      "abstract": "Let $F$ be a non-archimedean local field and $G$ the $F$-points of a connected simply-connected reductive group over $F$. In this paper, we study the unipotent $\\ell$-blocks of $G$, for $\\ell \\neq p$. To that end, we introduce the notion of $(d,1)$-series for finite reductive groups. These series form a partition of the irreducible representations and are defined using Harish-Chandra theory and $d$-Harish-Chandra theory. The $\\ell$-blocks are then constructed using these $(d,1)$-series, with $d$ the order of $q$ modulo $\\ell$, and consistent systems of idempotents on the Bruhat-Tits building of $G$. We also describe the stable $\\ell$-block decomposition of the depth zero category of an unramified classical group."
    }
  },
  {
    id: 4,
    tags: [1,3,7,9,10,11],
    publiType: 1,
    date: 2021,
    file: "equivalence-lanard.pdf",
    coAuthors: [],
    fr: {
      "title": "Equivalence of categories between coefficient systems and systems of idempotents",
      "journal": "Represent. Theory 25 (2021), 422-439",
      "abstract": "The consistent systems of idempotents of Meyer and Solleveld allow to construct Serre subcategories of $Rep_{R}(G)$, the category of smooth representations of a $p$-adic group $G$ with coefficients in $R$. In particular, they were used to construct level 0 decompositions when $R=\\overline{\\mathbb{Z}}_{\\ell}$, $\\ell \\neq p$, by Dat for $GL_n$ and the author for a more general group. Wang proved in the case of $GL_n$ that the subcategory associated with a system of idempotents is equivalent to a category of coefficient systems on the Bruhat-Tits building. This result was used by Dat to prove an equivalence between an arbitrary level zero block of $GL_n$ and a unipotent block of another group. In this paper, we generalize Wang's equivalence of category to a connected reductive group on a non-archimedean local field."
    },
    en: {
      "title": "Equivalence of categories between coefficient systems and systems of idempotents",
      "journal": "Represent. Theory 25 (2021), 422-439",
      "abstract": "The consistent systems of idempotents of Meyer and Solleveld allow to construct Serre subcategories of $Rep_{R}(G)$, the category of smooth representations of a $p$-adic group $G$ with coefficients in $R$. In particular, they were used to construct level 0 decompositions when $R=\\overline{\\mathbb{Z}}_{\\ell}$, $\\ell \\neq p$, by Dat for $GL_n$ and the author for a more general group. Wang proved in the case of $GL_n$ that the subcategory associated with a system of idempotents is equivalent to a category of coefficient systems on the Bruhat-Tits building. This result was used by Dat to prove an equivalence between an arbitrary level zero block of $GL_n$ and a unipotent block of another group. In this paper, we generalize Wang's equivalence of category to a connected reductive group on a non-archimedean local field."
    }
  },
  {
    id: 3,
    tags: [1,2,3,4,5,6,7,8,9],
    publiType: 1,
    date: 2021,
    file: "lblocslanard.pdf",
    coAuthors: [],
    fr: {
      "title": "Sur les $\\ell$-blocs de niveau zéro des groupes $p$-adiques II",
      "journal": "Ann. Sci. Éc. Norm. Supér. (4) 54 (2021), no. 3, 683–750",
      "abstract": "Soient $G$ un groupe $p$-adique se déployant sur une extension non-ramifiée et $Rep_{\\Lambda}^{0}(G)$ la catégorie abélienne des représentations lisses de $G$ de niveau 0 à coefficients dans $\\Lambda=\\overline{\\mathbb{Q}}_{\\ell}$ ou $\\overline{\\mathbb{Z}}_{\\ell}$. Nous étudions la plus fine décomposition de $Rep_{\\Lambda}^{0}(G)$ en produit de sous-catégories que l'on peut obtenir par la méthode introduite par Lanard, la seule méthode connue à ce jour lorsque $\\Lambda=\\overline{\\mathbb{Z}}_{\\ell}$ et $G$ n'est pas forme intérieure de $GL_n$. Nous en donnons deux descriptions, une première du côté du groupe à la Deligne-Lusztig, puis une deuxième du côté dual à la Langlands. Nous prouvons plusieurs propriétés fondamentales comme la compatibilité à l'induction et la restriction parabolique ou à la correspondance de Langlands locale. Les facteurs de cette décomposition ne sont pas des blocs, mais on montre comment les regrouper pour obtenir les blocs \"stables\". Certains de ces résultats corroborent une conjecture énoncée par Dat."
    },
    en: {
      "title": "Sur les $\\ell$-blocs de niveau zéro des groupes $p$-adiques II",
      "journal": "Ann. Sci. Éc. Norm. Supér. (4) 54 (2021), no. 3, 683–750",
      "abstract": "Let $G$ be a $p$-adic group which splits over an unramified extension and $Rep_{\\Lambda}^{0}(G)$ the abelian category of smooth level 0 representations of $G$ with coefficients in $\\Lambda=\\overline{\\mathbb{Q}}_{\\ell}$ or $\\overline{\\mathbb{Z}}_{\\ell}$. We study the finest decomposition of $Rep_{\\Lambda}^{0}(G)$ into a product of subcategories that can be obtained by the method introduced by Lanard, which is currently the only one available when $\\Lambda=\\overline{\\mathbb{Z}}_{\\ell}$ and $G$ is not an inner form of $GL_n$. We give two descriptions of it, a first one on the group side à la Deligne-Lusztig, and a second one on the dual side à la Langlands. We prove several fundamental properties, like for example the compatibility to parabolic induction and restriction or the compatibility to the local Langlands correspondence. The factors of this decomposition are not blocks, but we show how to group them to obtain \"stable\" blocks. Some of these results support a conjecture given by Dat."
    }
  },
  {
    id: 2,
    tags: [1,2,3,4,5,7,8,9],
    publiType: 1,
    date: 2018,
    file: "lblocs.pdf",
    coAuthors: [],
    fr: {
      "title": "Sur les $\\ell$-blocs de niveau zéro des groupes $p$-adiques",
      "journal": "Compos. Math. 154 (2018), no. 7",
      "abstract": "Soit $G$ un groupe $p$-adique se déployant sur une extension non-ramifiée. Nous décomposons $Rep_{\\Lambda}^{0}(G)$, la catégorie abélienne des représentations lisses de $G$ de niveau $0$ à coefficients dans $\\Lambda=\\overline{\\mathbb{Q}}_{\\ell}$ ou $\\overline{\\mathbb{Z}}_{\\ell}$, en un produit de sous-catégories indexées par des paramètres inertiels à valeurs dans le dual de Langlands. Ces dernières sont construites à partir de systèmes d'idempotents sur l'immeuble de Bruhat-Tits et de la théorie de Deligne-Lusztig. Nous montrons ensuite des compatibilités aux foncteurs d'induction et de restriction paraboliques ainsi qu'à la correspondance de Langlands locale."
    },
    en: {
      "title": "Sur les $\\ell$-blocs de niveau zéro des groupes $p$-adiques",
      "journal": "Compos. Math. 154 (2018), no. 7",
      "abstract": "Let $G$ be a $p$-adic group that splits over an unramified extension. We decompose $Rep_{\\Lambda}^{0}(G)$, the abelian category of smooth level $0$ representations of $G$ with coefficients in $\\Lambda=\\overline{\\mathbb{Q}}_{\\ell}$ or $\\overline{\\mathbb{Z}}_{\\ell}$, into a product of subcategories indexed by inertial Langlands parameters. We construct these categories via systems of idempotents on the Bruhat-Tits building and Deligne-Lusztig theory. Then, we prove compatibilities with parabolic induction and restriction functors and the local Langlands correspondence."
    }
  },
  {
    id: 1,
    tags: [1,2,3,4,5,6,7,8,9],
    publiType: 3,
    date: 2019,
    file: "These-Lanard.pdf",
    coAuthors: [],
    fr: {
      "title": "Sur les $\\ell$-blocs de niveau zéro des groupes $p$-adiques",
      "journal": "Thèse",
      "abstract": "Soit $G$ un groupe $p$-adique se déployant sur une extension non-ramifiée. Nous décomposons $Rep_{\\Lambda}^{0}(G)$, la catégorie abélienne des représentations lisses de $G$ de niveau $0$ à coefficients dans $\\Lambda=\\overline{\\mathbb{Q}}_{\\ell}$ ou $\\overline{\\mathbb{Z}}_{\\ell}$, en un produit de sous-catégories. Ces dernières sont construites à partir de systèmes d'idempotents sur l'immeuble de Bruhat-Tits et de la théorie de Deligne-Lusztig. Une première décomposition est obtenue à partir des paramètres inertiels à valeurs dans le dual de Langlands. Nous étudions ensuite la plus fine décomposition de $Rep_{\\Lambda}^{0}(G)$ que l'on puisse obtenir par cette méthode. Nous en donnons deux descriptions, une première du côté du groupe à la Deligne-Lusztig, puis une deuxième du côté dual à la Langlands. Nous prouvons plusieurs propriétés fondamentales comme la compatibilité à l'induction et la restriction parabolique ou à la correspondance de Langlands locale. Les facteurs de cette décomposition ne sont pas des blocs, mais on montre comment les regrouper pour obtenir les blocs \"stables\". Certains de ces résultats corroborent une conjecture énoncée par Dat. Nous montrons également que toutes ces catégories sont équivalentes à des catégories obtenues à partir de systèmes de coefficients sur l'immeuble. Enfin, nous obtenons la décomposition en $\\ell$-blocs dans certains cas particuliers."
    },
    en: {
      "title": "Sur les $\\ell$-blocs de niveau zéro des groupes $p$-adiques",
      "journal": "PhD Thesis",
      "abstract": "Soit $G$ un groupe $p$-adique se déployant sur une extension non-ramifiée. Nous décomposons $Rep_{\\Lambda}^{0}(G)$, la catégorie abélienne des représentations lisses de $G$ de niveau $0$ à coefficients dans $\\Lambda=\\overline{\\mathbb{Q}}_{\\ell}$ ou $\\overline{\\mathbb{Z}}_{\\ell}$, en un produit de sous-catégories. Ces dernières sont construites à partir de systèmes d'idempotents sur l'immeuble de Bruhat-Tits et de la théorie de Deligne-Lusztig. Une première décomposition est obtenue à partir des paramètres inertiels à valeurs dans le dual de Langlands. Nous étudions ensuite la plus fine décomposition de $Rep_{\\Lambda}^{0}(G)$ que l'on puisse obtenir par cette méthode. Nous en donnons deux descriptions, une première du côté du groupe à la Deligne-Lusztig, puis une deuxième du côté dual à la Langlands. Nous prouvons plusieurs propriétés fondamentales comme la compatibilité à l'induction et la restriction parabolique ou à la correspondance de Langlands locale. Les facteurs de cette décomposition ne sont pas des blocs, mais on montre comment les regrouper pour obtenir les blocs \"stables\". Certains de ces résultats corroborent une conjecture énoncée par Dat. Nous montrons également que toutes ces catégories sont équivalentes à des catégories obtenues à partir de systèmes de coefficients sur l'immeuble. Enfin, nous obtenons la décomposition en $\\ell$-blocs dans certains cas particuliers."
    }
  }
]